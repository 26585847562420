<template lang="html">
  <div class="" style="margin-top:-15px">
    <div class="mapouter">
        <div class="gmap_canvas"><iframe id="gmap_canvas"
                src="https://maps.google.com/maps?q=12%2C%20Avenue%20Comit%C3%A9%20Urbain%20%20Kinshasa%20%E2%80%93%20Gombe&t=&z=13&ie=UTF8&iwloc=&output=embed"
                frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a
                href="https://www.embedgooglemap.net"></a></div>
    </div>
    <!--  -->
    <div class="widget--fantasy">
      <div class="widget--fantasy-title">
        <h1 class="m-0">
          <span v-if="french">Nous contacter</span>
          <span v-if="english">Contact us</span>
        </h1>
      </div>
    </div>
    <!--  -->
    <section class="container mt-5">
      <div class="row">
        <div class="col-12 col-md-8 pr-md-5">
          <div class="row">
            <div class="col-12 col-sm-6" v-for="(presence, i) in presences" :key="presence.id" v-if="(i+1) % 2">
              <div class="panel panel-default widget--card-contact w-100 secondary--background widget--card-contact widget--borderless">
                <div class="panel-body">
                  <h5 class="mt-0">
                    <span class="widget--brand-color" v-if="english">{{presence.title.rendered}}</span>
                    <span class="widget--brand-color" v-if="french">{{presence.title.rendered}}</span>
                  </h5>
                  <div v-html="presence.acf.adresse_en" v-if="english"></div>
                  <div v-html="presence.acf.adresse_fr" v-if="french"></div>
                  <router-link tag="button" type="button" to="/message" class="btn btn--home-full mt-5">
                    <span v-if="french">Nous écrire</span>
                    <span v-if="english">Write to us</span>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6" v-for="(presence, i) in presences" :key="presence.id" v-if="(i+2) % 2">
              <div class="panel panel-default widget--card-contact w-100 secondary--background widget--card-contact widget--borderless">
                <div class="panel-body">
                  <h5 class="mt-0">
                    <span class="widget--brand-color" v-if="english">{{presence.title.rendered}}</span>
                    <span class="widget--brand-color" v-if="french">{{presence.title.rendered}}</span>
                  </h5>
                  <div v-html="presence.acf.adresse_en" v-if="english"></div>
                  <div v-html="presence.acf.adresse_fr" v-if="french"></div>
                  <router-link tag="button" type="button" to="/message" class="btn btn--home-full mt-5">
                    <span v-if="french">Nous écrire</span>
                    <span v-if="english">Write to us</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <Share :link="$route.fullPath" />
        </div>
        <!-- Sidebar -->
        <div class="col-12 col-md-4">
          <Sidebar />
        </div>
      </div>
    </section>
    <!--  -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { languageMixin } from '../../mixins/language'

export default {
  mixins: [languageMixin],
  computed: {
    ...mapGetters(['presences'])
  },
  created() {
    if (!this.presences.length) {
      this.$store.dispatch('getPresences')
    }
  }
}
</script>

<style lang="css">
</style>
